<template>
  <div>
    <section id="values" class="values">
      
          <b-tabs content-class="mt-3" fill>
    <b-tab title="Active Jobs" active>
      <p class="text-center">
        <b-spinner
          style="width: 5rem; height: 5rem"
          v-if="loading"
          label="Large Spinner"
        ></b-spinner>
      </p>
    <div class="row">
    <div
          class="col-lg-6 margin-20"
          data-aos="fade-up"
          v-for="(job, index) in jobs"
          :key="index"
        >
          <b-col md="12" class="box" >
            <b-row>
              <b-col md="6">
                <h3 class="text-left">Job Title</h3>
                <h6 class="text-left">{{ job.position }}</h6>
              </b-col>
              <b-col md="6">
                <h3 class="text-left">Job Type</h3>
                <h6 class="text-left">{{ job.employment_type }}</h6>
              </b-col>
              <b-col md="6">
                <b-button @click="routeTo('job_seekers', job)" variant="success">View Applicants</b-button>
              </b-col>
              <b-col md="6">
               <b-button @click="$bvModal.show('modal-'+job.id)"  variant="danger">Deactivate Jobs</b-button>
                <b-modal :id="'modal-'+job.id" title="Deactivate Job" @ok="deactivate(job)">
    <p class="my-4">Are You Sure To Deactivate The Job</p>

  </b-modal>
              </b-col>
            </b-row>
          </b-col>
        </div>
        </div>
    </b-tab>
    <b-tab title="Deactivated Jobs">
        <div class="row">
    <div
          class="col-lg-6 margin-20"
          data-aos="fade-up"
          v-for="(job, index) in deactivatedJobs"
          :key="index"
        >
          <b-col md="12" class="box" >
            <b-row>
              <b-col md="6">
                <h3 class="text-left">Job Title</h3>
                <h6 class="text-left">{{ job.position }}</h6>
              </b-col>
              <b-col md="6">
                <h3 class="text-left">Job Type</h3>
                <h6 class="text-left">{{ job.employment_type }}</h6>
              </b-col>
              <b-col md="6">
                <b-button @click="routeTo('job_seekers', job)" variant="success">View Applicants</b-button>
              </b-col>
              <b-col md="6">
               <b-button  @click="$bvModal.show('modal-'+job.id)"   variant="danger">Activate</b-button>
                <b-modal :id="'modal-'+job.id" title="Activate Job" @ok="activate(job)">
    <p class="my-4">Are You Sure To Activate The Job</p>

  </b-modal>
              </b-col>
            </b-row>
          </b-col>
        </div>
        </div>
    </b-tab>
</b-tabs>
        
      
    </section>
  </div>
</template>
<script>
import Vue from 'vue';
import { getFields, pathField } from "../../assets/js/service";
export default {
  watch:{
     "$store.state.loading": {
      handler() {
        this.loading = this.$store.state.loading;
      },
    },
  },
  data() {
    return {
      jobs: null,
      deactivatedJobs:null,
      loading:false,
    };
  },
  mounted() {
    let token = localStorage.getItem("token"); this.$store.commit("set", ["loading", true]);
    getFields("careers", token,{"type":"status","value":true}).then((resp) => {
      this.jobs = resp.data;
    });
     getFields("careers", token,{"type":"status","value":false}).then((resp) => {
      this.deactivatedJobs = resp.data;this.$store.commit("set", ["loading", false]);
    });
  },
  methods: {
    routeTo(route, job) {
      //   this.$router.push({ path: route, params: { id: job.id } })
      this.$router.push(route + "/" + job.id);
    },
    showModal(index) {
        this.$refs['my-modal-'+job.id].show()
      },
    deactivate(job){
        let token = localStorage.getItem('token');
        pathField('careers',job.id,{status:false},token).then(resp=>{
            getFields("careers", token,{"type":"status","value":true}).then((resp) => {
      this.jobs = resp.data;
    });
     getFields("careers", token,{"type":"status","value":false}).then((resp) => {
      this.deactivatedJobs = resp.data;
    });
             Vue.$toast.success("Successful", {
                  position: "top-right",
                  timeout: 5000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                });
        })
    },
        activate(job){
        let token = localStorage.getItem('token');
        pathField('careers',job.id,{status:true},token).then(resp=>{
            getFields("careers", token,{"type":"status","value":true}).then((resp) => {
      this.jobs = resp.data;
    });
     getFields("careers", token,{"type":"status","value":false}).then((resp) => {
      this.deactivatedJobs = resp.data;
    });
             Vue.$toast.success("Successful", {
                  position: "top-right",
                  timeout: 5000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                });
        })
    }
  },
};
</script>
<style>
</style>